import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><iframe src="https://docs.google.com/forms/d/18KPF5kLhoLVebSuV3M4khpfddubrVdTXMIOI0MVkfyg/viewform?embedded=true" width="100%" height="1120" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
      <style dangerouslySetInnerHTML={{
        __html: `
  main h1[itemprop="name"] {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
  }
`
      }} />
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Contact",
  "date": "2015-01-17T16:57:36.000Z",
  "overview": "Send me a few lines to ask a personal question, get in touch or to share a thought with me",
  "isInTopBar": true
};
    